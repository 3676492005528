import React from "react";
import { withTranslation } from "react-i18next";
import { Row } from "antd";

import ChatSwitchSubscribe from "../ChatSwitchSubscribe";
import LoadingSpin from "../../../../common/LoadingSpin";
import Icon from "../../../../common/UI/Icon";

import styles from "./chatHeader.less";

const ChatHeader = ({
  t,
  onChangeMute,
  subscribe,
  loadingMessages,
  visibleChatPopup,
  closeChatInModal,
  isPopupOpen
}) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className={
        isPopupOpen ? styles.sectionHeaderInPopup : styles.sectionHeader
      }
    >
      <div className={styles.sectionTitle}>
        {visibleChatPopup ? (
          <span className={styles.HideChatInPopup} onClick={closeChatInModal}>
            <Icon
              type="icon arrows-chevron-medium-big-2-01"
              className={styles.HideChatInPopupIcon}
            />
            <span className={styles.chatTitle}>
              {t("record.chat.hideComments")}
            </span>
          </span>
        ) : null}
        {!isPopupOpen && (
          <strong className={styles.chatTitle}>{t("record.chat.title")}</strong>
        )}
        {loadingMessages && <LoadingSpin className={styles.loadingSpin} />}
      </div>
      <ChatSwitchSubscribe checked={subscribe} onChange={onChangeMute} t={t} />
    </Row>
  );
};

export default withTranslation()(ChatHeader);
