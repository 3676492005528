import React, { useEffect, useState } from "react";

import Icon from "../../common/UI/Icon";
import ButtonTransparent from "../../common/UI/ButtonTransparent";

import filterActions from "../../../actions/filterActions";

import styles from "./filterNewMessages.less";

const FilterNewMessages = ({
  recordsCountNewMessages,
  sceneId,
  viewId,
  titleNewMessages,
  titleAll
}) => {
  const [filteredNewMessages, setFilteredNewMessages] = useState(false);
  useEffect(
    () => {
      if (recordsCountNewMessages < 1) {
        filterActions.filterByNewMessages(sceneId, false, {
          viewId
        });
      }
    },
    [recordsCountNewMessages]
  );
  const title = filteredNewMessages ? titleAll : titleNewMessages;

  const filterNewMessages = () => {
    if (recordsCountNewMessages !== 0) {
      filterActions.filterByNewMessages(sceneId, !filteredNewMessages, {
        viewId
      });
      setFilteredNewMessages(prev => !prev);
    }
  };
  return (
    <ButtonTransparent
      className={styles.filterNewMessagesButton}
      title={title}
      onClick={filterNewMessages}
    >
      <Icon
        type="icon communication-79"
        className={
          recordsCountNewMessages == 0 || filteredNewMessages
            ? styles.IconActiveFilterNewMessage
            : styles.IconFilterNewMessage
        }
      />
      <span className={styles.filterNewMessagesCount}>
        {recordsCountNewMessages}
      </span>
    </ButtonTransparent>
  );
};

export default FilterNewMessages;
