import Immutable from "immutable";
import Reflux from "reflux";
import _ from "lodash";

import appActions from "./actions/appActions";
import apiActions from "./actions/apiActions";
// // import routeAction from './actions/routeAction';
import recordActions from "./actions/recordActions";
import editorActions from "./actions/editorActions";
import sectionActions from "./actions/sectionActions";
import scriptActions from "./actions/scriptActions";
import availableLinkedRecordActions from "./actions/availableLinkedRecordActions";
import userSettingsActions from "./actions/userSettingsActions";
// import viewActions from './actions/viewActions'
import catalogActions from "./actions/catalogActions";
import historyActions from "./actions/historyActions";
import chatOptionActions from "./actions/chatOptionActions.js";
import messageActions from "./actions/messageActions";
import chatsStatusesActions from "./actions/chatsStatusesActions.js";
import chatsActions from "./actions/chatsActions.js";
import reportsActions from "./actions/reports";
import calendarActions from "./actions/calendarActions";
import linkedRecordActions from "./actions/linkedRecord";
import ChangesActions from "./actions/changesActions";
import appHeaderActions from "./actions/appHeader";
import uiActions from "./actions/uiActions";
import sceneActions from "./actions/sceneActions";
import importActions from "./actions/importActions";
import promiseQueueActions from "./actions/promiseQueueActions";
import batchRecordsActions from "./actions/batchRecordsActions.js";

import "./stores/UserSettingsStore";
import "./stores/RequestRecordStore";
import "./stores/ModalStore";

import * as LS from "./utils/storage";

import { DEFAULT } from "./configs/appModes";

const log = require("debug")("CRM:appState");

const storeMixins = [
  require("./stores/recordMixins").default,
  require("./stores/calendarMixin").default,
  require("./stores/sceneMixin").default,
  require("./stores/appMixin").default,
  require("./stores/sectionsMixin").default,
  require("./stores/scriptsMixin").default,
  require("./stores/catalogsMixin/index").default,
  require("./stores/editorMixin").default,
  require("./stores/availableLinkedRecordMixin").default,
  require("./stores/viewsMixin").default,
  require("./stores/filtersMixin").default,
  require("./stores/rightsMixin").default,
  require("./stores/privilegesMixin").default,
  require("./stores/filterKeysMixin").default,
  require("./stores/authMixin").default,
  require("./stores/userMixin").default,
  require("./stores/historyMixin").default,
  require("./stores/chatOptionMixin.js").default,
  require("./stores/messageMixin").default,
  require("./stores/reports/mixins").default,
  require("./stores/changesMixin").default,
  require("./stores/chatsStatusesMixin").default,
  require("./stores/chatsMixin").default,
  require("./stores/linkedRecordMixin").default,
  require("./stores/appHeader"),
  require("./stores/uiMixin").default,
  require("./stores/userSettingsMixin").default,
  require("./stores/importMixin").default,
  require("./stores/promiseQueueMixin").default,
  require("./stores/batchRecordsMixin").default,
  require("./stores/usersMixin").default
];

let apiHandlers = {};

storeMixins.forEach(m => {
  _.forEach(m, (fn, handlerName) => {
    if (handlerName === "init") {
      return;
    }
    if (apiHandlers[handlerName] == null) {
      apiHandlers[handlerName] = 0;
    }
    apiHandlers[handlerName]++;
  });
});

let aggregatedHandlers = {};
let originalMixins = storeMixins.slice();

_.forEach(apiHandlers, (count, handlerName) => {
  if (count > 1) {
    log(`Warning! '${handlerName}' fn declared ${count} times`);
    let fns = [];
    originalMixins.forEach(m => {
      _.forEach(m, (fn, fnName) => {
        if (fnName === handlerName) {
          fns.push(fn);
        }
      });
    });
    aggregatedHandlers[handlerName] = function(...args) {
      for (let i = 0; i < fns.length; i++) {
        fns[i].apply(this, args);
      }
    };
  }
});

storeMixins.push(aggregatedHandlers);

const STORAGE_KEY = "appState";

Reflux.StoreMethods.setAppState = function setAppState(st) {
  this.state = st;
};

const JSONparse = function(json) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return null;
  }
};

let state = Immutable.fromJS({
  // route: '',
  route: {},
  prevRoute: "",
  prevRouteParams: {},
  openRecord: null,

  dropType: null,
  dropInfo: null,

  initialDataLoading: false,
  initialDataLoaded: false,

  sections: {},
  sectionCreating: false,
  sectionCreateError: null,
  sectionsLoading: {},
  sectionsLoaded: {},
  sectionsLoadError: {},

  catalogs: {},
  catalogsLoading: false,
  catalogsLoadError: null,

  editingCatalogs: {},

  currentRecordId: null,
  currentRecordLoading: false,
  currentRecordLoadError: null,

  currentUser: null,
  currentUserLoading: false,

  createCatalogAllowed: null,

  records: {},
  newRecordId: {},

  changesResults: [],

  uploadingFiles: {},

  dropdownCollections: {},
  rights: [],

  privilegeCodesLoading: false,
  privilegeCodesLoaded: false,
  privilegeCodesByResource: {},

  company: {},
  license: {},
  activationCode: null,

  mode: DEFAULT,
  modalsFullScreen: false,

  boards: {
    loaded: {},
    loading: {},
    list: []
  },

  calendar: {
    date: "today",
    view: "month"
  },

  scenes: {},

  catalogsHistory: {},
  headerSubMenu: [],

  ui: {
    // UI component property
    helper: {
      isOpen: false,
      code: null,
      visited: {}
    },

    sidebarMenu: {
      fixed: LS.get("ui.sidebarMenu.fixed", true)
    },
    leftPanel: {
      visible: LS.get("ui.leftPanel.visible", true)
    }
  },

  userSettings: {
    ui: {
      favoriteCatalogs: [],
      visitedHelpers: [],
      shownHelpers: null,
      showChatTabInModal: null
    },
    catalogs: {}
  }
});

const appState = Reflux.createStore({
  mixins: storeMixins,
  listenables: [
    appActions,
    sectionActions,
    scriptActions,
    apiActions,
    recordActions,
    catalogActions,
    editorActions,
    availableLinkedRecordActions,
    userSettingsActions,
    historyActions,
    chatOptionActions,
    messageActions,
    chatsActions,
    chatsStatusesActions,
    reportsActions,
    calendarActions,
    linkedRecordActions,
    ChangesActions,
    appHeaderActions,
    uiActions,
    sceneActions,
    importActions,
    promiseQueueActions,
    batchRecordsActions
  ],

  init() {
    // this.listenTo(routeAction, this.onRouteChange);
    this.listen(() => log("changed"));
  },

  registerStore(store) {
    store.setAppState(this);
    this.listenTo(store, () => {
      log(`'${store.name}' triggered change`);
      this.changed();
    });
  },

  getState() {
    return state;
  },

  get(path) {
    return state.get(path);
  },

  getIn(path) {
    return state.getIn(path);
  },

  set(path, val) {
    state = state.set(path, val);
  },

  setState(newState) {
    state = newState;
  },

  setFromJS(path, val) {
    state = state.set(path, Immutable.fromJS(val));
  },

  updateIn(path, updater) {
    const currentValue = this.getIn(path);
    this.setIn(path, updater(currentValue));
  },

  pushIn(path, item) {
    this.updateIn(path, currentValue => currentValue.push(item));
  },

  setIn(path, val) {
    state = state.setIn(path, val);
  },

  // setDeepIn(path, val) {
  //   const checkPath = path.slice(0, -1);

  //   if (!state.getIn(checkPath)) {
  //     this.setDeepIn(checkPath, Immutable.Map());
  //   }

  //   state = state.setIn(path, val);
  // },

  changeIn(path, fn) {
    state = state.setIn(path, fn(state.getIn(path)));
  },

  mergeIn(path, val) {
    state = state.mergeIn(path, val);
  },

  mergeWith(path, callback, collection) {
    let value = this.getIn(path);

    if (!_.isObject(value)) {
      throw new Error("Value is not iterable");
    }

    collection.forEach((newValue, id) => {
      const oldValue = value.get(id);

      value = value.set(id, callback(oldValue, newValue));
    });

    state = state.setIn(path, value);
  },

  mergeDeepIn(path, val) {
    state = state.mergeDeepIn(path, val);
  },

  deleteIn(path) {
    state = state.deleteIn(path);
  },

  changed() {
    // log('changed');
    this.trigger(state);
  },

  saveToStorage: LS.set,

  loadFromStorage: LS.get,

  findById(pathToList, id) {
    let list = this.getIn(pathToList);
    return (list && list.find(o => o.get("id") === id)) || null;
  },

  toJS() {
    return state.toJS();
  }
});

window.appState = appState;

export default appState;
