import React, { useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "../../../StateProvider";

import TabChat from "./TabChat";
import messageActions from "../../../../actions/messageActions";

import styles from "./chat.less";

const TabChatController = ({
  catalog,
  record,
  sceneId,
  visibleChatPopup,
  closeChatInModal,
  isPopupOpen,
  records,
  t
}) => {
  const recordId = record.get("id");
  const chat = record.get("chat");

  const catalogId = catalog.get("id");
  const isNew = records.getIn([catalogId, recordId, "isNew"]);

  useLayoutEffect(() => {
    if (!chat) {
      if (isNew) return;
      messageActions.loadMessages(
        { catalogId, recordId, sceneId },
        true,
        50,
        true
      );
    }
  }, []);

  return recordId && chat ? (
    <TabChat
      {...{
        catalog,
        sceneId,
        catalogId,
        chat,
        record,
        recordId,
        visibleChatPopup,
        closeChatInModal,
        isPopupOpen
      }}
    />
  ) : isNew ? (
    <div className={styles.disabledChat}>
      <span>{t("record.chat.disabledComments")}</span>
    </div>
  ) : null;
};

TabChatController.propTypes = {
  catalog: PropTypes.object.isRequired,
  record: PropTypes.object
};

export default connect(
  TabChatController,

  { records: ["records"] }
);
