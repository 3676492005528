import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import cn from "classnames";
import Icon from "../../../common/UI/Icon";
import AbstractMenu from "../AbstractMenu";
import StateLink from "../../router/StateLink";
import NavLink from "../../router/Link";
import Badge from "../../UI/Badge";

import styles from "./listMenu.less";
import { connect } from "../../../StateProvider";

const ListMenu = ({ ...props }) => {
  const {
    items,
    route,
    params,
    dragType,
    canDrag,
    className,
    onDragEnd,
    direction,
    filterFn,
    defaultViewMode,
    location,
    catalogId,
    viewId,
    sidebarMenuIsFixed
  } = props;

  const [itemsToSelect, setItemsToSelect] = useState();

  useEffect(
    () => {
      const itemsToSelect = items.map(item => {
        const callFn = item.get("onClick");
        const icon = item.get("icon") ? (
          <Icon type={`icon ${item.get("icon")}`} className={cn(styles.icon)} />
        ) : null;
        const title = item.get("name");
        const text = <span className={styles.text}>{title}</span>;

        const componentRoute = item.get("route") || route;
        const componentParams =
          (params && {
            [params]: item.get("id")
          }) ||
          {};

        const LinkComponent =
          catalogId == item.get("id") && defaultViewMode ? StateLink : NavLink;

        if (callFn) {
          return (
            <a
              key={item.get("id")}
              rel="noreferrer"
              className={cn(styles.link, styles.addButton)}
              onClick={callFn}
              title={title}
            >
              {icon}
              {text}
            </a>
          );
        } else {
          return (
            <LinkComponent
              key={item.get("id")}
              route={componentRoute}
              params={componentParams}
              render={props => {
                return (
                  <Link
                    to={props.link}
                    className={cn(styles.link, {
                      [styles.selected]: props.isActive,
                      [styles.hiddenLink]: !sidebarMenuIsFixed
                    })}
                    title={title}
                  >
                    <Badge count={item.getIn(["chat", "newChats"])}>
                      {icon}
                    </Badge>
                    {text}
                  </Link>
                );
              }}
            />
          );
        }
      });
      setItemsToSelect(itemsToSelect);
    },
    [location, items, defaultViewMode, viewId]
  );

  return itemsToSelect ? (
    <AbstractMenu
      horizontal={{ menu: styles.horizontalMenu }}
      vertical={{ menu: styles.verticalMenu }}
      dragType={dragType}
      canDrag={canDrag}
      className={className}
      onDragEnd={onDragEnd}
      direction={direction}
      filterFn={filterFn}
      items={itemsToSelect}
    />
  ) : null;
};

export default connect(
  ListMenu,
  {
    userSettings: ["userSettings", "catalogs"]
  },
  (props, { userSettings }) => {
    const { catalogId } = props;
    const defaultViewMode =
      catalogId &&
      userSettings.getIn([catalogId, "options", "defaultViewMode", "value"]);
    const location = _.get(props, ["history", "location", "pathname"]);
    return {
      ...props,
      defaultViewMode,
      catalogId,
      location
    };
  }
);
