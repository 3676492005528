import { Row, Upload } from "antd";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import Icon from "../../../../../common/UI/Icon";
import ButtonTransparent from "../../../../../common/UI/ButtonTransparent";
import FileViewer from "../../../../../common/UI/ControlList/controls/fileViewer";
import ReplyMessage from "../../ReplyMessage/index";
import CustomSuggestionsContainer from "./CustomSuggestionsContainer";
import ButtonClose from "../../../../../common/UI/ButtonClose";
import MentionsInputContainer from "./MentionsInputContainer";
import RenderSuggestion from "./renderSuggestion";

import styles from "./chatInput.less";
import mentionsStyle from "./mentionsStyle.less";
import { antImageViewer } from "../../../../../common/UI/ControlList/controls/fileViewer/viewers/Image/AntImage";

const ChatInput = ({
  loadingAttachment,
  attachments,
  isBeingSentMessage,
  readyToShip,
  text,
  t,
  mentions,
  updateProgress,
  onClickRemoveFile,
  onChange,
  onKeyDown,
  sendMessage,
  handleFile,
  replyMessage,
  cancelReplyMessage,
  catalog,
  sceneId,
  icon = "icon communication-47",
  shouldFocusInput,
  isPopupOpen
}) => {
  const refContainer = useRef(null);
  const suggestionRef = useRef(null);
  /*ссылка для каждого инпута (для связывания инпута в случае, 
  если чат открыт из записи и далее переходим по юзеру в модальное окно с чатом),
   далее используется в suggestionsPortalHost*/

  function onChangeStatic(e) {
    const regex = /(?<=@)[^;]+(?=;)/g;
    let mentions = [...new Set(e.match(regex))];
    mentions = mentions.map(mention => {
      mention = mention.split(":");
      return {
        catalogId: mention[0],
        recordId: mention[1]
      };
    });
    onChange(e, mentions);
  }

  function onKeyDownStatic(e) {
    if (readyToShip) {
      onKeyDown(e);
    }
  }

  function sendMessageStatic() {
    if (readyToShip) {
      sendMessage();
    }
  }

  const calcSizes = (files, file, shipped) => {
    const separator = files.length > 2 ? 4.5 : 2;

    let widthOriginal = file.metadata?.size?.width;
    let heightOriginal = file.metadata?.size?.height;

    const size = {
      width: widthOriginal / separator,
      height: heightOriginal / separator
    };

    if (files.length > 2) {
      size.width = widthOriginal / (separator * 1.3);
      size.height = heightOriginal / (separator * 1.3);
    }

    if (widthOriginal <= 440 && heightOriginal <= 400) {
      size.width = widthOriginal;
      size.height = heightOriginal;
    }

    if (files.length > 4) {
      size.width = 100;
      size.height = 100;
    }

    if (!shipped) {
      size.width = 50;
      size.height = 50;
    }
    return size;
  };

  const disabledUpload =
    loadingAttachment ||
    (attachments && attachments.size > 10) ||
    isBeingSentMessage;

  return (
    <React.Fragment>
      {replyMessage && (
        <div className={styles.replyMessageContainerForInput}>
          <ReplyMessage
            author={replyMessage.get("author")}
            text={replyMessage.get("text")}
            attachments={replyMessage.get("attachments")}
          />
          {cancelReplyMessage && (
            <ButtonClose
              className={styles.replyMessageButtonCancel}
              onClick={cancelReplyMessage}
            />
          )}
        </div>
      )}
      <div ref={refContainer} className={styles.msgerInputarea}>
        <Row
          id="elem"
          ref={suggestionRef}
          type="flex"
          justify="space-between"
          className={
            isPopupOpen
              ? styles.msgerInputareaContainerInpupInPopup
              : styles.msgerInputareaContainerInput
          }
        >
          <MentionsInputContainer
            shouldFocusInput={shouldFocusInput}
            value={text}
            catalog={catalog}
            sceneId={sceneId}
            placeholder={t("record.chat.sendMessage.placeholder")}
            refContainer={refContainer}
            classNames={mentionsStyle}
            msgerInputareaMentionsButtonClassName={
              styles.msgerInputareaMentionsButton
            }
            wrapperClassName={styles.msgerWrapperInputMentions}
            mentions={mentions.toJS()}
            onKeyDown={onKeyDownStatic}
            customSuggestionsContainer={CustomSuggestionsContainer}
            renderSuggestion={RenderSuggestion}
            onChange={onChangeStatic}
            handleFile={handleFile}
            suggestionsPortalHost={suggestionRef.current}
          />
          <Row type="flex" align="middle">
            <Upload
              customRequest={handleFile}
              showUploadList={false}
              disabled={disabledUpload}
              multiple={true}
              maxCount={10}
            >
              <ButtonTransparent className={styles.msgerInputareaButton}>
                <Icon type="icon edition-49" />
              </ButtonTransparent>
            </Upload>
            <ButtonTransparent
              onClick={sendMessageStatic}
              className={classNames(styles.msgerInputareaButton, {
                [styles.msgerInputareaMentionsButtonReadyToShip]: readyToShip
              })}
            >
              <Icon type={icon} />
            </ButtonTransparent>
          </Row>
        </Row>
        {attachments.size > 0 && (
          <div className={styles.msgerInputAreaAttachContainer}>
            <FileViewer
              readOnly={false}
              files={attachments && attachments.toJS()}
              calcSizes={calcSizes}
              componentType={antImageViewer}
              updateProgress={updateProgress}
              onRemove={onClickRemoveFile}
              small={true}
              containerClassName={styles.msgerAttachAreaContent}
              wrapperClassName={styles.msgerAttachAreaWrapper}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ChatInput);
