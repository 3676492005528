// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recordBody__container___JR2iw {\n  height: calc(100% - 47px);\n  position: relative;\n}\n.recordBody__loadingOverlay___Jf9Xm {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 30px;\n}\n.recordBody__spinLoader___1eX2Z {\n  position: absolute;\n  top: 10px;\n  right: 0;\n}\n.recordBody__PopupWrapperWithChat___nJUGM {\n  position: relative;\n  display: flex;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordBody/recordBody.less"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,kBAAA;AAAF;AAGA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;AADF;AAIA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AAFF;AAKA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;AAHF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n.container {\n  height: calc(~\"100%\" - @HEADER_BASE);\n  position: relative;\n}\n\n.loadingOverlay {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 30px;\n}\n\n.spinLoader {\n  position: absolute;\n  top: @PADDING_BASE;\n  right: 0;\n}\n\n.PopupWrapperWithChat {\n  position: relative;\n  display: flex;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "recordBody__container___JR2iw",
	"loadingOverlay": "recordBody__loadingOverlay___Jf9Xm",
	"spinLoader": "recordBody__spinLoader___1eX2Z",
	"PopupWrapperWithChat": "recordBody__PopupWrapperWithChat___nJUGM"
};
export default ___CSS_LOADER_EXPORT___;
