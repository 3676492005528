import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "../../../StateProvider";
import userSettingsActions from "../../../../actions/userSettingsActions";
import uiActions from "../../../../actions/uiActions";

import RecordFields from "./RecordFields";
import Icon from "../../../common/UI/Icon";
import TabChatController from "../chatTab/TabChatController";

import styles from "./mainTab.less";

const log = require("debug")("CRM:Component:Record:TabMain");

const TabMain = props => {
  /*
    const tabLinkedData = !this.props.isNewRecord && (
      <TabLinkedData {...this.props} catalogId={catalogId} />
    );
    */

  const {
    isPopupOpen,
    showChatTabInModal,
    record,
    catalog,
    sceneId,
    t
  } = props;
  const [visibleChatPopup, setVisibleChatPopup] = useState(showChatTabInModal);

  const closeChatInModal = () => {
    setVisibleChatPopup(false);
    uiActions.setShowChatTabInModal(false);
    userSettingsActions.setKey(["ui", "showChatTabInModal"], false);
  };

  const openChatInModal = () => {
    setVisibleChatPopup(true);
    uiActions.setShowChatTabInModal(true);
    userSettingsActions.setKey(["ui", "showChatTabInModal"], true);
  };

  useEffect(() => {
    if (!_.isBoolean(showChatTabInModal)) {
      setVisibleChatPopup(true);
    }
  }, []);

  return (
    <div className={styles.tabMainContainer}>
      <div className={styles.recordFieldsContainer}>
        <RecordFields {...props} viewId={props.match.params.viewId} />
        {isPopupOpen &&
          (!visibleChatPopup && (
            <div className={styles.ShowChatInPopup} onClick={openChatInModal}>
              <Icon
                type="icon arrows-chevron-medium-big-1-01"
                className={styles.ShowChatInPopupIcon}
              />
              <span className={styles.chatTitle}>{t("record.chat.title")}</span>
            </div>
          ))}
      </div>
      {visibleChatPopup && isPopupOpen ? (
        <div className={styles.TabChatControllerContainer}>
          <TabChatController
            closeChatInModal={closeChatInModal}
            visibleChatPopup={visibleChatPopup}
            isPopupOpen={isPopupOpen}
            record={record}
            recordId={record.get("id")}
            catalog={catalog}
            sceneId={sceneId}
            t={t}
          />
        </div>
      ) : null}
    </div>
  );
};

TabMain.propTypes = {
  recordId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  onSaveField: PropTypes.func,
  onCreate: PropTypes.func,
  isNewRecord: PropTypes.bool,
  readOnly: PropTypes.bool,
  changefieldEditableStatus: PropTypes.func,
  fieldsEditableStatus: ImmutablePropTypes.map
};

export default connect(
  withRouter(TabMain),
  {
    showChatTabInModal: ["userSettings", "ui", "showChatTabInModal"]
  },
  (props, { showChatTabInModal }) => {
    return {
      ...props,
      showChatTabInModal
    };
  }
);
