import React from "react";
import _ from "lodash";
import appState from "../appState";
import debug from "debug";

const log = debug("StateProvider:");

function mapState(keys) {
  return _.mapValues(keys, key => appState.getIn(key));
}

function defaultMapProps(props, state) {
  return { ...props, ...state };
}

/**
 * @param Component {React.Component}
 * @param keys {Array|Object}
 * @param mapProps {function}
 */
export function connect(Component, keys, mapProps = defaultMapProps) {
  // normalize keys
  const normalKeys = _.isArray(keys)
    ? _(keys)
        .mapKeys(v => v)
        .mapValues(p => [p])
        .value()
    : _.mapValues(keys, key => [].concat(key));

  const componentName = Component.displayName || Component.name;

  return class StateProvider extends React.Component {
    static displayName = `connect(${componentName})`;

    constructor(...args) {
      super(...args);
      this.state = mapState(normalKeys);
      this.unsubscribe = appState.listen(this.onAppStateChange, this);

      log("initial", Component.name, keys, this.state);
    }

    onAppStateChange = () => {
      const newState = mapState(normalKeys);
      const isChanged = _.some(
        this.state,
        (value, key) => value !== newState[key]
      );
      if (isChanged) {
        log("changed", componentName, keys, newState);
        this.setState(newState);
      }
    };

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      const { props, state } = this;

      return <Component {...mapProps.call(this, props, state)} />;
    }
  };
}
